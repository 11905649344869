<template>
    <div class="template-page people">
        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <div>
                <div v-if="pageData.body" v-html="pageData.body[0].processed"></div>
            </div>
            <div class="people-wrapper">
                <transition-group name="list" tag="div">
                    <div v-for="(pers, index) in this.staffData" class="item-person" :key="'person'+index">
                        <router-link :to="personLink(pers)">
                            <person-tile :personData="pers"></person-tile>
                        </router-link>
                    </div>
                </transition-group>
            </div>
        </basic-page>
    </div>
</template>

<script>
import { fetchSingleNode, fetchNodes } from '../libs/drupalClient'
import BasicPage from './BasicPage.vue'
import PersonTile from '../components/PersonTile.vue'
import { detailsLink } from '../libs/utils'

export default {
  components: { BasicPage, PersonTile },
    name: 'contacts',
    data: () => {
        return {
            currentPageId: "b16dad7b-85cd-4dcf-9928-d36eff7b43eb",
            pageData: {},
            fulldata: {},
            staffData: {},
            bannerimg: null
        }
    },
    methods: {
        personLink(p) {
            return detailsLink('person', p);
        }
    },
    computed: {
    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top','field_page_image', 'field_attachments'] })
            .then(res => {
                this.pageData = res[0].attributes
                this.fulldata = res[0]
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })
        fetchNodes('person', {
            include: ['field_person_position', 'field_person_photo'],
        }).then(res => {

            
            res = res.filter(d=>{
                //console.log(d.attributes.field_person_contact_info);
                
                if(d.attributes.field_person_contact_info !== null){
                    return true
                }
                
            }) 

            /*
            res = res.filter(d => {
                let filter = false
                d.field_person_position.forEach(p => { 
                    if(p.attributes.name =='Administrative Staff') filter = true 
                })
                return filter
            })
            */

            this.staffData = res.sort((a,b)=>{
                if (a.attributes.field_last_name < b.attributes.field_last_name) { return -1; }
                if (a.attributes.field_last_name > b.attributes.field_last_name) { return 1; }
                return 0;
            });
        })
    }
}
</script>

<style lang="scss">
@import "../styles/colors.scss";

.person-wrapper {
  //margin: 20px auto;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  //height: 100%;
}

.people-wrapper {
    width: 100%;
    > div {
        //display: flex; display: -webkit-flex;
        //flex-wrap: wrap;
        //justify-content: left;
        //flex-direction: row;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 40px 0;
        max-width: 1200px;
        margin: 40px auto;

        .item-person {
            a { height: 100%; display: block; }

            //width: 25%;
            width: 100%;
            height: 100%;
            min-width: 265px;
            //max-width: 265px;

            /* @media (min-width: 1201px) {
                max-width: 300px;
            }

            @media (max-width: 768px) {
                max-width: unset;
                width: 45%;
            }

            @media (max-width: 576px) {
                //width: 90%;
                width: 100%;
                max-width: unset;
            } */
        }
    }
}

/* @media (max-width:768px){
    .people-wrapper > div {
        display: flex; display: -webkit-flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: row;
    }
} */

@media(max-width: 1200px) {
    .people-wrapper > div { grid-template-columns: repeat(3, 1fr); max-width: unset; }
}

@media(max-width: 992px) {
    .people-wrapper > div { grid-template-columns: repeat(2, 1fr); max-width: unset; }
}

@media(max-width: 576px) {
    .people-wrapper > div { grid-template-columns: 1fr; max-width: unset; }
}

</style>